//jQuery.noConflict();
jQuery.event.special.touchstart = {

  setup: function( _, ns, handle ){
    if ( ns.includes("noPreventDefault") ) {
      this.addEventListener("touchstart", handle, { passive: false });
    } else {
      this.addEventListener("touchstart", handle, { passive: true });
    }
  }

};

var $=jQuery.noConflict();

$(document).ready(function(){


   //$('.preloader-gif').fadeOut(); // will first fade out the loading animation 
    $('.preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website. 
    $('body').delay(350).css({'overflow':'visible'});


    $('.slide-principal').owlCarousel({
        pagination: true,
        nav: true,
        loop: false,
        margin: 0,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:1
            },
            1000:{
                items:1
            }
        }
        
    });


    $('.slide-proyectos').owlCarousel({
        pagination: true,
        nav: true,
        loop: false,
        dots: true,
        margin: 30,
        autoplay:false,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:2
            },
            1000:{
                items:3
            }
        }
        
    });


    $('.last-news').owlCarousel({
        pagination: true,
        nav: true,
        loop: false,
        dots: true,
        margin: 30,
        autoplay:false,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:2
            },
            1000:{
                items:3
            }
        }
        
    });




    $('.logos').owlCarousel({
        pagination: true,
        nav: true,
        loop: false,
        dots: true,
        margin: 30,
        autoplay:false,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:4
            },
            768:{
                items:5
            },
            1000:{
                items:7
            }
        }
        
    });



    $( ".owl-prev").html('<i class="fa fa-angle-left"></i>');
    $( ".owl-next").html('<i class="fa fa-angle-right"></i>');









    // Highlight the top nav as scrolling occurs
    $(window).scroll(function() {
    if ($(document).scrollTop() > 400) {
        $('.auto-hide-header').addClass('shrink');
    } else {
        $('.auto-hide-header').removeClass('shrink');
    }
    });

    // Move element
    $(".auto-hide-header").autoHidingNavbar({
        animationDuration:900,
    });


    // jQuery for page scrolling feature - requires jQuery Easing plugin
    $('a.page-scroll').bind('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top - 50)
        }, 1250, 'easeInOutExpo');

        event.preventDefault();
    });

    // Anchor scroll
    var $root = $('html, body');
    $('a.anchor').click(function() {
        $root.animate({
            scrollTop: $( $.attr(this, 'href') ).offset().top - 110
        }, 1200);
        return false;
    });




    // Cerrar menu al hacer click
    $('.navigation a').on('click', function(){
        $( ".nav-trigger" ).toggleClass( "collapsed" );
        $( ".navigation" ).toggleClass( "in" );
    });


    


   
        $(".magia input").val("");


        $(".magia input").focusout(function(){
            if($(this).val() !== ""){
                $(this).addClass("has-content");
            }else{
                $(this).removeClass("has-content");
            }
        });

  




    // Magic popup
    $('.popup-gallery').each(function() { // the containers for all your galleries
        $(this).magnificPopup({
            delegate: 'a', // the selector for gallery item
            type: 'image',
            gallery: {
            enabled:true
            }
        });
    });





    // Add class a todos los href a images
    $('.post-text a[href*=".png"], .post-text a[href*=".jpg"]').each(function() {
        if (this.href.indexOf('?') < 0) {
            $(this).addClass('popup-link');
        }
    });

    $('.popup-link').magnificPopup({
    type: 'image'
    // other options
    });


    $('.popup-modal').magnificPopup({
    type:'inline',
    midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    });


    // Responsive iframes
    $("iframe[src*='youtube']​​​​​​​​​​​​​​​​").wrap('<div class="embed-responsive embed-responsive-16by9"/>');
    $("iframe[src*='youtube']​​​​​​​​​​​​​​​​").addClass('embed-responsive-item');

    // Responsive iframes
    fluidvids.init({
    selector: ['iframe'],
    players: ['www.youtube.com', 'player.vimeo.com']
    });

    $(".video").wrap('<div class="embed-responsive embed-responsive-16by9"/>');
    $(".video").addClass('embed-responsive-item');




});
